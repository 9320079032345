/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'PopupHitCardV2', // Test 463
  'NewReleasePageV2', // Test 441
  'ProductSteamReviews', // Test 447
  'ProductSteamReviewsMobile', // Test 448
  'BYOExitOfferModal', // Test 451
  'PopularCategoriesTabs', // Test 457
  'CartMysertyUpsellSizes', // Test 458
  'NoResultsTopSellers', // Test 461
  'OnSalePageListing', // Test 462
  'UserRecommendationsMegaMenu', // Test 455
];
